const auditStatusOptions = [
  {
    value: 0,
    label: "待审核",
  },
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核驳回",
  },
];
const showStatusOptions = [
  {
    value: 0,
    label: "隐藏",
  },
  {
    value: 1,
    label: "展示",
  },
];
export { auditStatusOptions, showStatusOptions };
